<hls-header (menuClick)="drawer.toggle()"></hls-header>

<mat-sidenav-container>
  <mat-sidenav
    #drawer
    [mode]="sidenavMode"
    [opened]="sidenavShouldOpen"
    [fixedInViewport]="true"
    [fixedTopGap]="56"
  >
    <hls-sidenav
      (buttonClick)="sidenavShouldOpen ? null : drawer.toggle()"
    ></hls-sidenav>
  </mat-sidenav>
  <mat-sidenav-content class="drawer-content">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
