<div class="sidenav">
  <mat-nav-list class="list">
    <a
      mat-list-item
      routerLink="/youtube-channel"
      routerLinkActive="active"
      (click)="buttonClick.emit()"
    >
      <mat-icon mat-list-icon svgIcon="youtube"></mat-icon>
      <p mat-line i18n="@@youtubeChannel">YouTube Channel</p>
    </a>
    <!--<a
      mat-list-item
      routerLink="/bilibili-channel"
      routerLinkActive="active"
      (click)="buttonClick.emit()"
    >
      <mat-icon mat-list-icon svgIcon="bilibili"></mat-icon>
      <p mat-line i18n="@@bilibiliChannel">Bilibili Channel</p>
    </a>-->
    <a
      mat-list-item
      routerLink="/youtube-stream"
      routerLinkActive="active"
      (click)="buttonClick.emit()"
    >
      <mat-icon mat-list-icon svgIcon="stream"></mat-icon>
      <p mat-line i18n="@@youtubeStream">YouTube Stream</p>
    </a>
    <a
      mat-list-item
      routerLink="/youtube-schedule-stream"
      routerLinkActive="active"
      (click)="buttonClick.emit()"
    >
      <mat-icon mat-list-icon svgIcon="calendar"></mat-icon>
      <p mat-line i18n="@@youtubeSchedule">YouTube Schedule</p>
    </a>
    <a
      mat-list-item
      routerLink="/settings"
      routerLinkActive="active"
      (click)="buttonClick.emit()"
    >
      <mat-icon mat-list-icon svgIcon="tune"></mat-icon>
      <p mat-line i18n="@@settings">Settings</p>
    </a>
  </mat-nav-list>

  <mat-divider></mat-divider>

  <div class="info">
    <div>
      Please note that this site is not affiliated with 
      <a class="link"
        href="https://vt.poi.cat/"
        target="_blank"
      >
        vtstats</a> owned by PoiScript.
    </div>

    <div class="row">
      <a class="link" href="https://angular.io/" target="_blank">
        Angular: v{{ angularVer }}
      </a>
    </div>

      <hls-update-indicator></hls-update-indicator>
  </div>
  <div class="copyright">&copy; 2019-2022 PoiScript</div>
</div>
