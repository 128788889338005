type VTuber = {
  id: string;
  twitter?: string;
  youtube?: string;
  bilibili?: number;
  default?: true;
};

export type VTuberIds =
  | "yoshika"
  | "shijouyuri"
  | "usuwasuu"
  | "figaro"
  | "shishigamileona"
  | "ibaramuan"
  | "akumasaki"
  | "hizukirurufu"
  | "yumemitobira"
  | "hachi"
  | "yumekawakanau"
  | "awayukiyuri"
  | "enmaruri"
  | "machitachima"
  | "matorikakeru"
  | "misakiberry"
  | "hiyorilara"
  | "matsunagaiori"
  | "yasotoiki"
  | "manomueru"
  | "ashizawasaki"
  | "kanademimi"
  | "delutaya"
  | "manae"
  | "kogumaneko"
  | "boogeyvoxx"
  | "sola"
  | "mair"
  | "meigarden"
  | "utanoneru"
  | "amamania"
  | "quontama"
  | "domyojicocoa"
  | "tsukishirocecil"
  | "mikado"
  | "tsukushiaria"
  | "nekohakoron"
  | "otsukaray"
  | "asuyumekanae"
  | "yoakeichika"
  | "linatadayoi"
  | "kouitusin"
  | "noir"
  | "shiinananoha"
  | "samayoisuzu"
  | "setonototo"
  | "takeda"
  | "mashiroyuki"
  | "taoshan"
  | "minaiaruma"
  | "kamuriamuru"
  | "yuudutsutear"
  | "hinatanemenma"
  | "yashiromamea"
  | "mochimochisakura"
  | "saikawahayuri"
  | "usami"
  | "mokan"
  | "yupsilon"
  | "orcaluca"
  | "saotomeazuki"
  | "utenhiyori"
  | "shairu"
  | "mus1ca"
  | "komorikomari"
  | "aokamamiya"
  | "kitaharakimaseri"
  | "kadukimikuru"
  | "yumesakimia"
  | "hinatsunono"
  | "sonosakihiyuri"
  | "togenukiibara"
  | "shimanagaena"
  | "tachibanashiena"
  | "maoura"
  | "shiki"
  | "akashiamane"
  | "amatsukaseu"
  | "jinkyouta"
  | "sakuyaazusa"
  | "kitakojihisui"
  | "amounoel"
  | "kumadarin"
  | "shikimamuku"
  | "chachahinata"
  | "kaminoharaink"
  | "lunariaweupe"
  | "hananokimaru"
  | "inuzukiren"
  | "tetourakiiru"
  | "femmefatale"
  | "nanafujichihane"
  | "yuimaru"
  | "kanaruhanon"
  | "garcia"
  | "eruse"
  | "nayukivivy"
  | "sumi"
  | "momosehibana"
  | "nekomatama"
  | "nekomeishinemo"
  | "hosizorakokono"
  | "mokz"
  | "mireasheltzs"
  | "hishimochihamu"
  | "menilriche"
  | "rekuiemurii"
  | "kaminohikari"
  | "yayamugi"
  | "kurumizawamomo"
  | "inashino"
  | "noapolaris"
  | "tokiwaaino"
  | "mizuhanesora"
  | "bosuke"
  | "amanosekori"
  | "monakamokoko"
  | "makohlisa"
  | "abyssdiva"
  | "momozonorieru"
  | "utashiroamane"
  | "chitogetoge"
  | "tsuku"
  | "turuuiko"
  | "momosenina"
  | "sakuragimiria"
  | "wanomiya"
  | "nekotokanami"
  | "kulakulie"
  | "aonolunon"
  | "kurenagieri"
  | "coucouruacreil"
  | "shiroseaoi"
  | "tokusarikukuri"
  | "kasyouwaon"
  | "usanenoa"
  | "keita"
  | "lilylinglan"
  | "mikan"
  | "hasumisahiro"
  | "ririsya"
  | "umitsukishell"
  | "kohakunuino"
  | "tenjinkotone"
  | "ayamonono"
  | "soreyueni"
  | "amagumonana"
  | "momijimaru"
  | "kandukiama"
  | "munmosh"
  | "hachiyania"
  | "sakuratonagi"
  | "nekoamitozi"
  | "kuryuisana"
  | "kitanoeko"
  | "tsukishironoela"
  | "minazukireru"
  | "cerise"
  | "kikomaya"
  | "hanatabayuiha"
  | "suzukazeshitora"
  | "mikagurasuzume"
  | "mikazukichiyuru"
  | "shiratamautano"
  | "hanamariyura"
  | "naginomashiro"
  | "amahanemiran"
  | "hibikikasane"
  | "kumanophay"
  | "minaseshizuku"
  | "moemidan"
  | "taito"
  | "shichihachikumano"
  | "nomuranimu"
  | "nicolaaldin"
  | "akechimitsuki"
  | "tobanaiturara"
  | "nyatenmei"
  | "rokurisyao"
  | "kakushakurin"
  | "kokuyohnoi"
  | "hayamamidori"
  | "sis"
  | "chiyourachiyomi"
  | "himenonoe"
  | "micosmicbaby"
  | "mizuboshiyamu"
  | "kyokashino";
 
export const vtubers: Record<VTuberIds, VTuber> = {
  yoshika: {
    id: "yoshika",
    youtube: "UC3xQCiEPSkco54WhuiDcngw",
    twitter: "yosi_k14",
    default: true
  },
  shijouyuri: {
    id: "shijouyuri",
    youtube: "UCNwmt2Cq1SvkUDRvG3F_QaQ",
    twitter: "shijou_yuri",
    default: true
  },
  usuwasuu: {
    id: "usuwasuu",
    youtube: "UCeqIMtLuGc3YgwkhEaG8oDg",
    twitter: "usuwasuu",
    default: true
  },
  figaro: {
    id: "figaro",
    youtube: "UC7CtvN04ublW4LGTPksG6mg",
    twitter: "figaro_qpt",
    default: true
  },
  shishigamileona: {
    id: "shishigamileona",
    youtube: "UCB1s_IdO-r0nUkY2mXeti-A",
    twitter: "leonashishigami",
    default: true
  },
  ibaramuan: {
    id: "ibaramuan",
    youtube: "UC9zLKU6WiRdcKtAh-6o_zmA",
    twitter: "ibaramuan",
    default: true
  },
  akumasaki: {
    id: "akumasaki",
    youtube: "UC-SSusywniohnRHif72eYdw",
    twitter: "saki_akuma",
    default: true
  },
  hizukirurufu: {
    id: "hizukirurufu",
    youtube: "UCcQsDietWkYakBKbGpCaeLA",
    twitter: "RurufuHizuki",
    default: true
  },
  yumemitobira: {
    id: "yumemitobira",
    youtube: "UCN2uDnQBkmrdzTH26LtzKiA",
    twitter: "10bira_",
    default: true
  },
  hachi: {
    id: "hachi",
    youtube: "UC7XCjKxBEct0uAukpQXNFPw",
    twitter: "8HaChi_hacchi",
    default: true
  },
  yumekawakanau: {
    id: "yumekawakanau",
    youtube: "UC8jskpQfW9fn2NLK3PdaGdg",
    twitter: "Kanau_Yumekawa",
    default: true
  },
  awayukiyuri: {
    id: "awayukiyuri",
    youtube: "UCKWFm_17yck-zK4qE5CCSFw",
    twitter: "Yuri_awayuki",
    default: true
  },
  enmaruri: {
    id: "enmaruri",
    youtube: "UC1nqXaKzG4hd1SRFVra16gw",
    twitter: "Ruri_Enma",
    default: true
  },
  machitachima: {
    id: "machitachima",
    youtube: "UCo7TRj3cS-f_1D9ZDmuTsjw",
    twitter: "chima_machita23",
    default: true
  },
  matorikakeru: {
    id: "matorikakeru",
    youtube: "UCiLt4FLjMXszLOh5ISi1oqw",
    twitter: "matorikakeru",
    default: true
  },
  misakiberry: {
    id: "misakiberry",
    youtube: "UC7A7bGRVdIwo93nqnA3x-OQ",
    twitter: "MaisakiBerry",
    default: true
  },
  hiyorilara: {
    id: "hiyorilara",
    youtube: "UCXDgfG0_f8ObbNRgpHrYfkw",
    twitter: "HiyoriLara",
    default: true
  },
  matsunagaiori: {
    id: "matsunagaiori",
    youtube: "UC--zuEfONeFXPvLqX0Kvbuw",
    twitter: "iori_m_RIOT",
    default: true
  },
  yasotoiki: {
    id: "yasotoiki",
    youtube: "UCNj88lUpk9fyzQydjbsuzRw",
    twitter: "yaso_toiki",
    default: true
  },
  manomueru: {
    id: "manomueru",
    youtube: "UCFqVAFANOHm17UVy8sWkhqw",
    twitter: "Mano_muerU",
    default: true
  },
  ashizawasaki: {
    id: "ashizawasaki",
    youtube: "UCPZgBtMYoFKypEG2SCvBN9A",
    twitter: "saki_ashizawa",
    default: true
  },
  kanademimi: {
    id: "kanademimi",
    youtube: "UCpHIwGHq_3OfX42cyfyJp-A",
    twitter: "kanademimimi",
    default: true
  },
  delutaya: {
    id: "delutaya",
    youtube: "UC7YXqPO3eUnxbJ6rN0z2z1Q",
    twitter: "mgmgoi4",
    default: true
  },
  manae: {
    id: "manae",
    youtube: "UCAPdxmEjYxUdQMf_JaQRl1Q",
    twitter: "manae_nme",
    default: true
  },
  kogumaneko: {
    id: "kogumaneko",
    youtube: "UCfxn5cmOYnC2y6Qpb2TQgsg",
    twitter: "K0guma_nek0",
    default: true
  },
  boogeyvoxx: {
    id: "boogeyvoxx",
    youtube: "UCtk3KnxYCoGiyrLVdIOgG1Q",
    twitter: "_BOOGEY_VOXX_",
    default: true
  },
  sola: {
    id: "sola",
    youtube: "UC23wZiGcf1ZlmTRZDfkagew",
    twitter: "sola_she",
    default: true
  },
  mair: {
    id: "mair",
    youtube: "UCsiKFVHkQSMlSe0vaCG0anw",
    twitter: "MaiR_Hoshino",
    default: true
  },
  meigarden: {
    id: "meigarden",
    youtube: "UCJkbFDMgYIC-uM4uj6Z4IbQ",
    twitter: "mei_garden",
    default: true
  },
  utanoneru: {
    id: "utanoneru",
    youtube: "UCYsZcucFXXwtAaWNvjIkZaA",
    twitter: "neru_utano",
    default: true
  },
  amamania: {
    id: "amamania",
    youtube: "UC3KldSym-HTLMkvitStsNTw",
    twitter: "amamania_vtuber",
    default: true
  },
  quontama: {
    id: "quontama",
    youtube: "UCBC7vYFNQoGPupe5NxPG4Bw",
    twitter: "quon01tama",
    default: true
  },
  domyojicocoa: {
    id: "domyojicocoa",
    youtube: "UC5Xse6X7vB_nUL5HvEFVo_g",
    twitter: "cocoa_domyoji",
    default: true
  },
  tsukishirocecil: {
    id: "tsukishirocecil",
    youtube: "UCNtwkcCmjmPIqVYWkhnzjdw",
    twitter: "cecil_moonlight",
    default: true
  },
  mikado: {
    id: "mikado",
    youtube: "UCO-FZDAG0mOvgu6jmGg1tiA",
    twitter: "Mikado_HS",
    default: true
  },
  tsukushiaria: {
    id: "tsukushiaria",
    youtube: "UC5XQhzMH08PgWa4Zp02Gcsw",
    twitter: "tsukushi_aria",
    default: true
  },
  nekohakoron: {
    id: "nekohakoron",
    youtube: "UCO_o0X1-ESEk8LEr1g9x1wg",
    twitter: "nekoha_koron",
    default: true
  },
  otsukaray: {
    id: "otsukaray",
    youtube: "UCrAsByHNIFObjr2sEaZHkCg",
    twitter: "rayotsuka",
    default: true
  },
  asuyumekanae: {
    id: "asuyumekanae",
    youtube: "UCM48FPVMJopLzOsVuEJb-Zg",
    twitter: "asuyumekanae",
    default: true
  },
  yoakeichika: {
    id: "yoakeichika",
    youtube: "UCx-SnFvoy-MPZ8Ea-jaK1sA",
    twitter: "YoakeIchika",
    default: true
  },
  linatadayoi: {
    id: "linatadayoi",
    youtube: "UCI0vsOl2fo-h44RfA6ATUjw",
    twitter: "ln_yoi",
    default: true
  },
  kouitusin: {
    id: "kouitusin",
    youtube: "UCxsnprsgzFSj5lPguLei9_w",
    twitter: "kouitu_sin",
    default: true
  },
  noir: {
    id: "noir",
    youtube: "UCRQa9FvT-ZlxQrN6B2HjIgQ",
    twitter: "No_iR88",
    default: true
  },
  shiinananoha: {
    id: "shiinananoha",
    youtube: "UC0lIq8G4LgDPlXsDmYSUExw",
    twitter: "ShiinaNanoha78",
    default: true
  },
  samayoisuzu: {
    id: "samayoisuzu",
    youtube: "UCqDN60QQx3GGmfagx1eyIEg",
    twitter: "samayoisuzu",
    default: true
  },
  setonototo: {
    id: "setonototo",
    youtube: "UC-M0Poxo4VXMxdIzinqWOig",
    twitter: "setono_toto1010",
    default: true
  },
  takeda: {
    id: "takeda",
    youtube: "UC1wmieYGo7GsOYWBk1qGzMw",
    twitter: "takeda252525",
    default: true
  },
  mashiroyuki: {
    id: "mashiroyuki",
    youtube: "UC8LJJ1HOq_z8Uaycot6L_Xw",
    twitter: "mashiro3yuki",
    default: true
  },
  taoshan: {
    id: "taoshan",
    youtube: "UCnUhgh7rNaLnvmya--q0pCw",
    twitter: "taoshan_v",
    default: true
  },
  minaiaruma: {
    id: "minaiaruma",
    youtube: "UCD1QOCJIAPsMKMvRSXjLahw",
    twitter: "MinaiAlma",
    default: true
  },
  kamuriamuru: {
    id: "kamuriamuru",
    youtube: "UCbbpq_-t_DsVFEc2yjnurwA",
    twitter: "Kamuri_Amuru",
    default: true
  },
  yuudutsutear: {
    id: "yuudutsutear",
    youtube: "UC1bB4d5Bgv6XnZoN3Lk1qOg",
    twitter: "Yuudutsu_Tear",
    default: true
  },
  hinatanemenma: {
    id: "hinatanemenma",
    youtube: "UC2gVj3bLfZgxi23gBNO8ZHg",
    twitter: "_menma0",
    default: true
  },
  yashiromamea: {
    id: "yashiromamea",
    youtube: "UC6qkE98WkN8no-Zp7KBnvzw",
    twitter: "Mea_Yashiroma",
    default: true
  },
  mochimochisakura: {
    id: "mochimochisakura",
    youtube: "UCLOnKw76ypBM5PeYr4OdkJQ",
    twitter: "mochisakura0228",
    default: true
  },
  saikawahayuri: {
    id: "saikawahayuri",
    youtube: "UCJCHA7JkpKwbpS_DAF4AHpQ",
    twitter: "SaikawaHayuri",
    default: true
  },
  usami: {
    id: "usami",
    youtube: "UCvPlw6DtODOTwt76eaJg4Dw",
    twitter: "Usami_chami",
    default: true
  },
  mokan: {
    id: "mokan",
    youtube: "UCvCzeR_L0PbngKQqYE8VncQ",
    twitter: "mokan_mikan",
    default: true
  },
  yupsilon: {
    id: "yupsilon",
    youtube: "UCFjU-kRnqfn9wtcGsFbe7aA",
    twitter: "yupsilon_",
    default: true
  },
  orcaluca: {
    id: "orcaluca",
    youtube: "UCwvOBQgmWOoMR6pF4yKfMmg",
    twitter: "luca_orca",
    default: true
  },
  saotomeazuki: {
    id: "saotomeazuki",
    youtube: "UCzyhBffWik7EShr6PEMsVSQ",
    twitter: "azukisekihann",
    default: true
  },
  utenhiyori: {
    id: "utenhiyori",
    youtube: "UC7b4GklPQO9ZnV7mqnJzN8g",
    twitter: "UtenHiyori",
    default: true
  },
  shairu: {
    id: "shairu",
    youtube: "UCe_8qM8Wk4cU5oD3WO07gZg",
    twitter: "Shairu_Vt",
    default: true
  },
  mus1ca: {
    id: "mus1ca",
    youtube: "UC3zSmwgOOIjWUbus4cZIUPg",
    twitter: "MUS1CA_vsinger",
    default: true
  },
  komorikomari: {
    id: "komorikomari",
    youtube: "UCBydbirLw7hmnuty-pLEXOQ",
    twitter: "komokoma_ri",
    default: true
  },
  aokamamiya: {
    id: "aokamamiya",
    youtube: "UCNUJNr17-atlSOifaTwSPsg",
    twitter: "mamiyanV3838",
    default: true
  },
  kitaharakimaseri: {
    id: "kitaharakimaseri",
    youtube: "UC6EgjUZSYSAQe9CDFxH715w",
    twitter: "kimaserikita",
    default: true
  },
  kadukimikuru: {
    id: "kadukimikuru",
    youtube: "UCo5pboqAxtr5gOFSDBGD09w",
    twitter: "kadukimikuru",
    default: true
  },
  yumesakimia: {
    id: "yumesakimia",
    youtube: "UCc5bWVuWyL74ngKNjm49hdw",
    twitter: "yumesakimia",
    default: true
  },
  hinatsunono: {
    id: "hinatsunono",
    youtube: "UCPeF0V8dzSUYHwgLjvC86PQ",
    twitter: "HinatsuNono",
    default: true
  },
  sonosakihiyuri: {
    id: "sonosakihiyuri",
    youtube: "UCvBH0IjfnMbLx0aRc31ThMw",
    twitter: "Hiyuri__Snsk",
    default: true
  },
  togenukiibara: {
    id: "togenukiibara",
    youtube: "UCkybB-Rhid_jFlJB0-Hie2Q",
    twitter: "ibara_togenuki",
    default: true
  },
  shimanagaena: {
    id: "shimanagaena",
    youtube: "UCaXfFDauhubIgmaTKxM74Kw",
    twitter: "Ena_Shimanaga",
    default: true
  },
  tachibanashiena: {
    id: "tachibanashiena",
    youtube: "UCCCl9iDRWCYtTTVm7T0diMA",
    twitter: "tachibana_haco",
    default: true
  },
  maoura: {
    id: "maoura",
    youtube: "UCPEKpqpHu0ojN8lJnMUGjaQ",
    twitter: "maoura_RB",
    default: true
  },
  shiki: {
    id: "shiki",
    youtube: "UCEq0JuGLxvCFMy6XXpmDgig",
    twitter: "shiki_RB",
    default: true
  },
  akashiamane: {
    id: "akashiamane",
    youtube: "UCO5Wq_Ddvet-KJWeOP8wtKQ",
    twitter: "akashiamane_RB",
    default: true
  },
  amatsukaseu: {
    id: "amatsukaseu",
    youtube: "UCIq2Q41fxZkkSaDJn06mtvw",
    twitter: "amatsuka_seu",
    default: true
  },
  jinkyouta: {
    id: "jinkyouta",
    youtube: "UC4XWwxd382MzLeZMD6hOjOw",
    twitter: "jinkyouta",
    default: true
  },
  sakuyaazusa: {
    id: "sakuyaazusa",
    youtube: "UCpNu3ngL8XHyQ0-T5I8dB2g",
    twitter: "sakuya_azusa",
    default: true
  },
  kitakojihisui: {
    id: "kitakojihisui",
    youtube: "UCRqBKoKuX30ruKAq05pCeRQ",
    twitter: "Hisui_Kitakoji",
    default: true
  },
  amounoel: {
    id: "amounoel",
    youtube: "UCkHCX-gTDBvWPfgMTvA3WqA",
    twitter: "Amou_Noel",
    default: true
  },
  kumadarin: {
    id: "kumadarin",
    youtube: "UCngECy3lUQYQ6_uVoqX4vbA",
    twitter: "Kumada_Rin",
    default: true
  },
  shikimamuku: {
    id: "shikimamuku",
    youtube: "UCmstLWY1Hcf-uK9CWbU5KBg",
    twitter: "shikima_muku_sb",
    default: true
  },
  chachahinata: {
    id: "chachahinata",
    youtube: "UC0UjtG03CK055b84MZ4n2_Q",
    twitter: "cha_hinata",
    default: true
  },
  kaminoharaink: {
    id: "kaminoharaink",
    youtube: "UCPuBPs-2CgGSI2j2OhdyCng",
    twitter: "ink_kaminohara",
    default: true
  },
  lunariaweupe: {
    id: "lunariaweupe",
    youtube: "UCwHNZABe7ptMY1dZls_xFAw",
    twitter: "Lunaria_weupe",
    default: true
  },
  hananokimaru: {
    id: "hananokimaru",
    youtube: "UCmB1E78Kdgd9z6hN3ONRKow",
    twitter: "hananoki_maru",
    default: true
  },
  inuzukiren: {
    id: "inuzukiren",
    youtube: "UCg4x4QmjPx0I1HNQp9h1xvg",
    twitter: "inuzuki_vt",
    default: true
  },
  tetourakiiru: {
    id: "tetourakiiru",
    youtube: "UCSIrS0pGVomLEZ853mZwVEw",
    twitter: "4kiiru",
    default: true
  },
  femmefatale: {
    id: "femmefatale",
    youtube: "UCjooy4G0O8D-4KkE1SWr5Jg",
    twitter: "femme_fatale777",
    default: true
  },
  nanafujichihane: {
    id: "nanafujichihane",
    youtube: "UC8knjhuDug5iyn_WJkIfc6Q",
    twitter: "nnfjchn",
    default: true
  },
  yuimaru: {
    id: "yuimaru",
    youtube: "UCiw0IHf0T1XWNDgTOA57CbQ",
    twitter: "yuimaru_1031",
    default: true
  },
  kanaruhanon: {
    id: "kanaruhanon",
    youtube: "UCay6Y3oEoiC6ZEE2G0UZu_A",
    twitter: "kanaruhanon",
    default: true
  },
  garcia: {
    id: "garcia",
    youtube: "UClntoLgaEF_JN0wid_3mZbA",
    twitter: "LR_GarciaV",
    default: true
  },
  eruse: {
    id: "eruse",
    youtube: "UCLMC54YctropOupFX8jyz9A",
    twitter: "Else_PJblue",
    default: true
  },
  nayukivivy: {
    id: "nayukivivy",
    youtube: "UCdesGlD7t5JV-z28p5GqVIw",
    twitter: "Nayuki_Vivy",
    default: true
  },
  sumi: {
    id: "sumi",
    youtube: "UCNethwV7AkG4NKfNGaCuXMg",
    twitter: "sumisuya",
    default: true
  },
  momosehibana: {
    id: "momosehibana",
    youtube: "UCGLrsPlc2DpavAxmj1QV1tw",
    twitter: "hibana_moyuru",
    default: true
  },
  nekomatama: {
    id: "nekomatama",
    youtube: "UCYV0ojRpol74EwfVSuuqWxA",
    twitter: "nekoma_tama",
    default: true
  },
  nekomeishinemo: {
    id: "nekomeishinemo",
    youtube: "UCot9C2dq8JTzfFRkVsHOB8Q",
    twitter: "nekomenemo",
    default: true
  },
  hosizorakokono: {
    id: "hosizorakokono",
    youtube: "UCGs11j2SuGNcZGawgeUGC_w",
    twitter: "hosizorakokono",
    default: true
  },
  mokz: {
    id: "mokz",
    youtube: "UCYZUujsTn8uopC8gwBxXFPQ",
    twitter: "mkz_cb",
    default: true
  },
  mireasheltzs: {
    id: "mireasheltzs",
    youtube: "UCIumx9FItlv6B_JsHVMjVYA",
    twitter: "Mirea_Sheltzs",
    default: true
  },
  hishimochihamu: {
    id: "hishimochihamu",
    youtube: "UCZEKM9D3ij7G29MIDDPdG6w",
    twitter: "Salapro_Mochi",
    default: true
  },
  menilriche: {
    id: "menilriche",
    youtube: "UC_6_mNPR0neopAgV8IjowNw",
    twitter: "menil_riche",
    default: true
  },
  rekuiemurii: {
    id: "rekuiemurii",
    youtube: "UC36tM-mb6ve_OA3jPynxp7g",
    twitter: "REKUIEMURII",
    default: true
  },
  kaminohikari: {
    id: "kaminohikari",
    youtube: "UC_kJ27jGTWYniW-Xy3Ms5Cw",
    twitter: "kaminohikarin",
    default: true
  },
  yayamugi: {
    id: "yayamugi",
    youtube: "UCDqno_7LWobowaVc_vzUuCA",
    twitter: "yaya_mugi",
    default: true
  },
  kurumizawamomo: {
    id: "kurumizawamomo",
    youtube: "UCxrmkJf_X1Yhte_a4devFzA",
    twitter: "kurumizawamomo",
    default: true
  },
  inashino: {
    id: "inashino",
    youtube: "UCzvxzjCfdZZ3X8ERSTEEZQA",
    twitter: "inashino",
    default: true
  },
  noapolaris: {
    id: "noapolaris",
    youtube: "UCIu1rRiQLeUU8e1saN6I0eg",
    twitter: "noa_polaris",
    default: true
  },
  tokiwaaino: {
    id: "tokiwaaino",
    youtube: "UCocnY-kJ7VYVA7SQLP8AHIA",
    twitter: "tokiwa_aino",
    default: true
  },
  mizuhanesora: {
    id: "mizuhanesora",
    youtube: "UCF7bh0hs92ASGjt3iIMq5fw",
    twitter: "MizuhaneSora",
    default: true
  },
  bosuke: {
    id: "bosuke",
    youtube: "UCUH3EFf51jWYG38tEUjRMmA",
    twitter: "_Bosuke",
    default: true
  },
  amanosekori: {
    id: "amanosekori",
    youtube: "UClC-BQmQwY96J-Xb-rHh8Ig",
    twitter: "amanose_kori",
    default: true
  },
  monakamokoko: {
    id: "monakamokoko",
    youtube: "UClsdKKXIhONjuN8FJH6w6sg",
    twitter: "mokoko_monaka",
    default: true
  },
  makohlisa: {
    id: "makohlisa",
    youtube: "UCDrG8pzkq_cCCLcN5wgrzNw",
    twitter: "makohlisam",
    default: true
  },
  abyssdiva: {
    id: "abyssdiva",
    youtube: "UCKeA3GiW32eSZNdKK_ouw-A",
    twitter: "LuKia_DIVA",
    default: true
  },
  momozonorieru: {
    id: "momozonorieru",
    youtube: "UCG60AciQFwS6Q22njFpEHuQ",
    twitter: "riel_momozono",
    default: true
  },
  utashiroamane: {
    id: "utashiroamane",
    youtube: "UCL-soYzCYr0oUJxJUBJnyvQ",
    twitter: "AmaneUtashiro",
    default: true
  },
  chitogetoge: {
    id: "chitogetoge",
    youtube: "UC--CacRBYYCYag2no58_IIQ",
    twitter: "kurome_oni329",
    default: true
  },
  tsuku: {
    id: "tsuku",
    youtube: "UCzY38g1OV_1AsPlJMwx5fSw",
    twitter: "yasaiumai000",
    default: true
  },
  turuuiko: {
    id: "turuuiko",
    youtube: "UCZeLd3Cu2QtT8U8H2IZb1ug",
    twitter: "26ongaesi",
    default: true
  },
  momosenina: {
    id: "momosenina",
    youtube: "UCGCNass-jSHshAHA4ufX3Ng",
    twitter: "momose_nina",
    default: true
  },
  sakuragimiria: {
    id: "sakuragimiria",
    youtube: "UCWq6L3iY4hGK4LmylvQtj-g",
    twitter: "Miria_Sakuragi_",
    default: true
  },
  wanomiya: {
    id: "wanomiya",
    youtube: "UCXZfeD1dHjWO7XRrMQmli_A",
    twitter: "wanomiya_",
    default: true
  },
  nekotokanami: {
    id: "nekotokanami",
    youtube: "UCZwsOhl_T0k2Sgf7S3JReNQ",
    twitter: "NekotoKanami",
    default: true
  },
  kulakulie: {
    id: "kulakulie",
    youtube: "UCxLgvntienV0kwNLxrbcmyQ",
    twitter: "LieKulaku",
    default: true
  },
  aonolunon: {
    id: "aonolunon",
    youtube: "UCchK9jCrG88ymPXt5y8f98w",
    twitter: "aonolunon",
    default: true
  },
  kurenagieri: {
    id: "kurenagieri",
    youtube: "UCQYzqKdEiWfyYU1IAnN2S-Q",
    twitter: "Kurenagieri",
    default: true
  },
  coucouruacreil: {
    id: "coucouruacreil",
    youtube: "UCIueYPQm6sk8oaKxq0hSjyQ",
    twitter: "CoucouruaCreil",
    default: true
  },
  shiroseaoi: {
    id: "shiroseaoi",
    youtube: "UC4SUl-nTftCcl3t7NKPd0_Q",
    twitter: "shiroseaoi",
    default: true
  },
  tokusarikukuri: {
    id: "tokusarikukuri",
    youtube: "UC0gbkS-xhcWWXfWugQUSRuw",
    twitter: "tokusari_kukuri",
    default: true
  },
  kasyouwaon: {
    id: "kasyouwaon",
    youtube: "UCyZhvlsSoPFFPDj5ueChzrg",
    twitter: "Kasyou_waonnn",
    default: true
  },
  usanenoa: {
    id: "usanenoa",
    youtube: "UC4A6p4aAPuUFGJ4d8VEj7Jw",
    twitter: "usane_noa",
    default: true
  },
  keita: {
    id: "keita",
    youtube: "UCpIIJGtpQFkkcjzqxNgyeyg",
    twitter: "keserasera68",
    default: true
  },
  lilylinglan: {
    id: "lilylinglan",
    youtube: "UC3ZTQ8VZVCpwLHjFKSFe5Uw",
    twitter: "linglanthebox",
    default: true
  },
  mikan: {
    id: "mikan",
    youtube: "UC1JuhRTsFgZvi2ie2dTUxbg",
    twitter: "mikannovocal",
    default: true
  },
  hasumisahiro: {
    id: "hasumisahiro",
    youtube: "UCaHgUmTyQhth2s07ChoKJuw",
    twitter: "HasumiSahiro",
    default: true
  },
  ririsya: {
    id: "ririsya",
    youtube: "UC1ucgoC_sGww_Euu5iMqpQw",
    twitter: "Ririsya_music",
    default: true
  },
  umitsukishell: {
    id: "umitsukishell",
    youtube: "UCz1RAeb7dVaUcd-4rEgyxPw",
    twitter: "umitsukishell",
    default: true
  },
  kohakunuino: {
    id: "kohakunuino",
    youtube: "UCF4KiwafRPMgvnfipsk1JZg",
    twitter: "Kohaku_Nuino",
    default: true
  },
  tenjinkotone: {
    id: "tenjinkotone",
    youtube: "UCAr7rLi_Wn09G-XfTA07d4g",
    twitter: "kotonegami",
    default: true
  },
  ayamonono: {
    id: "ayamonono",
    youtube: "UCtKH7ZHAJ7TcTjyiLJRHhvg",
    twitter: "Nono_Ayamo",
    default: true
  },
  soreyueni: {
    id: "soreyueni",
    youtube: "UC-Fzm_O1oGFa4C3wQOAeU3g",
    twitter: "sore_yueni",
    default: true
  },
  amagumonana: {
    id: "amagumonana",
    youtube: "UC5oDG8MOn36cklTYI_J03ZQ",
    twitter: "amagumonana",
    default: true
  },
  momijimaru: {
    id: "momijimaru",
    youtube: "UCll1L35_pBwCnjdNL06tuZA",
    twitter: "momiji_maru_",
    default: true
  },
  kandukiama: {
    id: "kandukiama",
    youtube: "UCkIoEPncHPIWByx7OlzEVXQ",
    twitter: "kandukiama",
    default: true
  },
  munmosh: {
    id: "munmosh",
    youtube: "UC7cZJOAJZD1W4aOfqnRgWiA",
    twitter: "MunMosh",
    default: true
  },
  hachiyania: {
    id: "hachiyania",
    youtube: "UCH5DBAOT0QyebrMzC7iLobA",
    twitter: "88nia_desuna",
    default: true
  },
  sakuratonagi: {
    id: "sakuratonagi",
    youtube: "UC4JMJYf5OEZLB8fG6XcLkdw",
    twitter: "Nagi_Sakurato",
    default: true
  },
  nekoamitozi: {
    id: "nekoamitozi",
    youtube: "UCE0Aruy_hbfpeEhP5LuQ_Lg",
    twitter: "mitozi_nekoa",
    default: true
  },
  kuryuisana: {
    id: "kuryuisana",
    youtube: "UC01dI90qNB6RhCt1tdXnQAg",
    twitter: "IsanaKuryu",
    default: true
  },
  kitanoeko: {
    id: "kitanoeko",
    youtube: "UC2lw2kyLpgJ5RmXO8hKdNMA",
    twitter: "EchoNorth_",
    default: true
  },
  tsukishironoela: {
    id: "tsukishironoela",
    youtube: "UC84A6rC8cTVD1KsGmxS-Kcw",
    twitter: "noela_tk",
    default: true
  },
  minazukireru: {
    id: "minazukireru",
    youtube: "UCeVr_dAdntbgOZvVGAJi4Fw",
    twitter: "minaduki_relle",
    default: true
  },
  cerise: {
    id: "cerise",
    youtube: "UC9u-zCU10QH-hkSPLFzFc0g",
    twitter: "cerise_SprWitch",
    default: true
  },
  kikomaya: {
    id: "kikomaya",
    youtube: "UCr5NUbBdf6UDm9y2oSwSFNA",
    twitter: "KikoMayaYP",
    default: true
  },
  hanatabayuiha: {
    id: "hanatabayuiha",
    youtube: "UCRGoi4YW5afhZukGo3P8H_Q",
    twitter: "hanataba_yuiha",
    default: true
  },
  suzukazeshitora: {
    id: "suzukazeshitora",
    youtube: "UCSH2LgTRhPCsaVPW_emgDJg",
    twitter: "suzukazeshitora",
    default: true
  },
  mikagurasuzume: {
    id: "mikagurasuzume",
    youtube: "UCqsS32C_llpT2ia5S1Oh44A",
    twitter: "suzume_mikagura",
    default: true
  },
  mikazukichiyuru: {
    id: "mikazukichiyuru",
    youtube: "UCNN0n29dfU-F3DQ4YvEenuQ",
    twitter: "Chiyuru_nyaaaa",
    default: true
  },
  shiratamautano: {
    id: "shiratamautano",
    youtube: "UCNskpCCH661BeRJkN8n8d-A",
    twitter: "shiratamautano",
    default: true
  },
  hanamariyura: {
    id: "hanamariyura",
    youtube: "UCpYzhFyrOzDmJm4gnsE0R5w",
    twitter: "hanamari_yura",
    default: true
  },
  naginomashiro: {
    id: "naginomashiro",
    youtube: "UCewRhO43UmIohSBzW2HMheA",
    twitter: "Nagino_Mashiro",
    default: true
  },
  amahanemiran: {
    id: "amahanemiran",
    youtube: "UCUWiEBm4pi_ApdyRPq_Sb8w",
    twitter: "AmahaneMiran",
    default: true
  },
  hibikikasane: {
    id: "hibikikasane",
    youtube: "UCH1B9AR8sfWJVf9zcu9rbGg",
    twitter: "Kasane_CT",
    default: true
  },
  kumanophay: {
    id: "kumanophay",
    youtube: "UCr6rRU8fI3rRC-psnLlDW8g",
    twitter: "phay_kumano",
    default: true
  },
  minaseshizuku: {
    id: "minaseshizuku",
    youtube: "UCfNzetCx7owyP5n1t2rmBrQ",
    twitter: "sizuku_minase",
    default: true
  },
  moemidan: {
    id: "moemidan",
    youtube: "UCL7NohDGFGRZiTfSDMR7c7A",
    twitter: "dantencho",
    default: true
  },
  taito: {
    id: "taito",
    youtube: "UCe-tjSYbvkYxLVajPAVm2TQ",
    twitter: "Taito_Vsinger",
    default: true
  },
  shichihachikumano: {
    id: "shichihachikumano",
    youtube: "UC_4x4gCnCkJ2o-VINN0AJ6A",
    twitter: "kumano_78",
    default: true
  },
  nomuranimu: {
    id: "nomuranimu",
    youtube: "UC3eXEL0ZurrfG774l4qIqtg",
    twitter: "nimunimudesuyo",
    default: true
  },
  nicolaaldin: {
    id: "nicolaaldin",
    youtube: "UCJLeqQq4GENW8e7nfyDdyXw",
    twitter: "Nicola_aldinVt",
    default: true
  },
  akechimitsuki: {
    id: "akechimitsuki",
    youtube: "UCrDRepCC81GaakxeWwJhMFA",
    twitter: "Unifee_akechi",
    default: true
  },
  tobanaiturara: {
    id: "tobanaiturara",
    youtube: "UCO-Bxy1KJ9Zf1oWoed2nc9A",
    twitter: "toberuzo_turara",
    default: true
  },
  nyatenmei: {
    id: "nyatenmei",
    youtube: "UCa0wEwSQOwijQr6GfJAGlnw",
    twitter: "mei_cats_angle",
    default: true
  },
  rokurisyao: {
    id: "rokurisyao",
    youtube: "UC-C18fxv_QHfeqehxvFg4Cw",
    twitter: "syaorkr",
    default: true
  },
  kakushakurin: {
    id: "kakushakurin",
    youtube: "UCe_vSZivfbWX2v7LjIvWgJg",
    twitter: "kakusyakurin",
    default: true
  },
  kokuyohnoi: {
    id: "kokuyohnoi",
    youtube: "UC0LE80T_3rgob808pMdzyHQ",
    twitter: "noi_kokuyoh",
    default: true
  },
  hayamamidori: {
    id: "hayamamidori",
    youtube: "UCa_QYqKVEOE_EIZWgiOEZ5A",
    twitter: "Boss_01xl",
    default: true
  },
  sis: {
    id: "sis",
    youtube: "UCcELCuFkHaHhXq8VzH-8saA",
    twitter: "sis_anubas",
    default: true
  },
  chiyourachiyomi: {
    id: "chiyourachiyomi",
    youtube: "UCyY6YeINiwQoA-FnmdQCkug",
    twitter: "tiyoura_tiyomi",
    default: true
  },
  himenonoe: {
    id: "himenonoe",
    youtube: "UCCrreV17y33Qf1jY6OjBuIw",
    twitter: "himeno_noe",
    default: true
  },
  micosmicbaby: {
    id: "micosmicbaby",
    youtube: "UCxn-IeA9DCFxUthBk6rYdQQ",
    twitter: "MiCosmiCbaby",
    default: true
  },
  mizuboshiyamu: {
    id: "mizuboshiyamu",
    youtube: "UCQ_xdlyjMpB-1OoKWEfF6HQ",
    twitter: "mizuboshi_yamu",
    default: true
  },
  kyokashino: {
    id: "kyokashino",
    youtube: "UC2YWl46WAMLjcXfjAXxTZUQ",
    twitter: "Kyoka_Shino",
    default: true
  }
};

export type BatchIds =
  | "react"
  | "axelv"
  | "snacknili"
  | "liveunion"
  | "nijisanji"
  | "riotmusic"
  | "drop"
  | "guildcq"
  | "hoshimeguri"
  | "nkshoujo"
  | "reface"
  | "yoshika"
  | "shijouyuri"
  | "figaro"
  | "ibaramuan"
  | "yumemitobira"
  | "awayukiyuri"
  | "misakiberry"
  | "hiyorilara"
  | "manomueru"
  | "kanademimi"
  | "delutaya"
  | "manae"
  | "kogumaneko"
  | "boogeyvoxx"
  | "sola"
  | "alivemusix"
  | "meigarden"
  | "utanoneru"
  | "amamania"
  | "tsukishirocecil"
  | "nekohakoron"
  | "otsukaray"
  | "asuyumekanae"
  | "yoakeichika"
  | "linatadayoi"
  | "noir"
  | "shiinananoha"
  | "samayoisuzu"
  | "takeda"
  | "mashiroyuki"
  | "kamuriamuru"
  | "hinatanemenma"
  | "yashiromamea"
  | "saikawahayuri"
  | "usami"
  | "mokan"
  | "yupsilon"
  | "orcaluca"
  | "saotomeazuki"
  | "utenhiyori"
  | "shairu"
  | "mus1ca"
  | "komorikomari"
  | "aokamamiya"
  | "kitaharakimaseri"
  | "propro"
  | "hinatsunono"
  | "sonosakihiyuri"
  | "togenukiibara"
  | "shimanagaena"
  | "haconect"
  | "riotblue"
  | "amatsukaseu"
  | "yunoproduction"
  | "startup"
  | "kumadarin"
  | "shikimamuku"
  | "kaminoharaink"
  | "lunariaweupe"
  | "vtuberschool"
  | "inuzukiren"
  | "zepro"
  | "nanafujichihane"
  | "yuimaru"
  | "paletteproject"
  | "garcia"
  | "eruse"
  | "renewlive"
  | "sumi"
  | "momosehibana"
  | "nekomatama"
  | "nekomeishinemo"
  | "hosizorakokono"
  | "mokz"
  | "mireasheltzs"
  | "hishimochihamu"
  | "coconpapillon"
  | "rekuiemurii"
  | "yayamugi"
  | "noripro"
  | "inashino"
  | "noapolaris"
  | "tokiwaaino"
  | "bosuke"
  | "scproj"
  | "monakamokoko"
  | "abyssdiva"
  | "nonamepro"
  | "enfuse"
  | "chitogetoge"
  | "tsuku"
  | "turuuiko"
  | "projectnebula"
  | "sakuragimiria"
  | "wanomiya"
  | "nekotokanami"
  | "aonolunon"
  | "tokusarikukuri"
  | "kasyouwaon"
  | "usanenoa"
  | "keita"
  | "thebox"
  | "mikan"
  | "hasumisahiro"
  | "ririsya"
  | "umitsukishell"
  | "kohakunuino"
  | "tenjinkotone"
  | "ayamonono"
  | "soreyueni"
  | "amagumonana"
  | "momijimaru"
  | "kandukiama"
  | "munmosh"
  | "hachiyania"
  | "sakuratonagi"
  | "nekoamitozi"
  | "kitanoeko"
  | "tsukishironoela"
  | "minazukireru"
  | "cerise"
  | "kikomaya"
  | "hanatabayuiha"
  | "shinengumi"
  | "mikagurasuzume"
  | "mikazukichiyuru"
  | "univirtual"
  | "utatanepro"
  | "naginomashiro"
  | "starring"
  | "hibikikasane"
  | "kumanophay"
  | "minaseshizuku"
  | "moemidan"
  | "taito"
  | "shichihachikumano"
  | "nomuranimu"
  | "nanahapi"
  | "unifee"
  | "tobanaiturara"
  | "nyatenmei"
  | "rokurisyao"
  | "kakushakurin"
  | "kokuyohnoi"
  | "hayamamidori"
  | "sis"
  | "aogirikoukou"
  | "himenonoe"
  | "micosmicbaby"
  | "mizuboshiyamu"
  | "kyokashino";

type Batch = VTuberIds[];

export const batches: Record<BatchIds, Batch> = {
  axelv: ["akumasaki"],
  snacknili: ["hizukirurufu", "taoshan"],
  react: ["usuwasuu", "shishigamileona", "yumekawakanau", "tsukushiaria", "makohlisa", "kulakulie", "coucouruacreil", "kuryuisana"],
  liveunion: ["hachi", "enmaruri", "setonototo"],
  nijisanji: ["machitachima", "kitakojihisui"],
  riotmusic: ["matsunagaiori", "ashizawasaki", "domyojicocoa"],
  drop: ["yasotoiki"],
  alivemusix: ["mair"],
  guildcq: ["quontama", "minaiaruma"],
  hoshimeguri: ["mikado", "mochimochisakura", "kaminohikari"],
  nkshoujo: ["kouitusin"],
  reface: ["yuudutsutear"],
  propro: ["kadukimikuru", "yumesakimia", "sakuyaazusa", "mizuhanesora", "shiroseaoi"],
  haconect: ["tachibanashiena"],
  riotblue: ["maoura", "shiki"],
  yunoproduction: ["jinkyouta", "chachahinata"],
  startup: ["amounoel"],
  vtuberschool: ["hananokimaru"],
  zepro: ["femmefatale"],
  paletteproject: ["kanaruhanon"],
  renewlive: ["nayukivivy"],
  coconpapillon: ["menilriche", "tetourakiiru"],
  noripro: ["kurumizawamomo"],
  scproj: ["amanosekori"],
  nonamepro: ["momozonorieru"],
  enfuse: ["utashiroamane"],
  projectnebula: ["momosenina"],
  thebox: ["lilylinglan"],
  shinengumi: ["matorikakeru", "suzukazeshitora"],
  univirtual: ["shiratamautano"],
  utatanepro: ["hanamariyura"],
  starring: ["amahanemiran"],
  nanahapi: ["nicolaaldin"],
  unifee: ["akechimitsuki"],
  aogirikoukou: ["chiyourachiyomi"],
  yoshika: null,
  shijouyuri: null,
  figaro: null,
  ibaramuan: null,
  yumemitobira: null,
  awayukiyuri: null,
  misakiberry: null,
  hiyorilara: null,
  manomueru: null,
  kanademimi: null,
  delutaya: null,
  manae: null,
  kogumaneko: null,
  boogeyvoxx: null,
  sola: null,
  meigarden: null,
  utanoneru: null,
  amamania: null,
  tsukishirocecil: null,
  nekohakoron: null,
  otsukaray: null,
  asuyumekanae: null,
  yoakeichika: null,
  linatadayoi: null,
  noir: null,
  shiinananoha: null,
  samayoisuzu: null,
  takeda: null,
  mashiroyuki: null,
  kamuriamuru: null,
  hinatanemenma: null,
  yashiromamea: null,
  saikawahayuri: null,
  usami: null,
  mokan: null,
  yupsilon: null,
  orcaluca: null,
  saotomeazuki: null,
  utenhiyori: null,
  shairu: null,
  mus1ca: null,
  komorikomari: null,
  aokamamiya: null,
  kitaharakimaseri: null,
  hinatsunono: null,
  sonosakihiyuri: null,
  togenukiibara: null,
  shimanagaena: null,
  amatsukaseu: null,
  kumadarin: null,
  shikimamuku: null,
  kaminoharaink: null,
  lunariaweupe: null,
  inuzukiren: null,
  nanafujichihane: null,
  yuimaru: null,
  garcia: null,
  eruse: null,
  sumi: null,
  momosehibana: null,
  nekomatama: null,
  nekomeishinemo: null,
  hosizorakokono: null,
  mokz: null,
  mireasheltzs: null,
  hishimochihamu: null,
  rekuiemurii: null,
  yayamugi: null,
  inashino: null,
  noapolaris: null,
  tokiwaaino: null,
  bosuke: null,
  monakamokoko: null,
  abyssdiva: null,
  chitogetoge: null,
  tsuku: null,
  turuuiko: null,
  sakuragimiria: null,
  wanomiya: null,
  nekotokanami: null,
  aonolunon: null,
  tokusarikukuri: null,
  kasyouwaon: null,
  usanenoa: null,
  keita: null,
  mikan: null,
  hasumisahiro: null,
  ririsya: null,
  umitsukishell: null,
  kohakunuino: null,
  tenjinkotone: null,
  ayamonono: null,
  soreyueni: null,
  amagumonana: null,
  momijimaru: null,
  kandukiama: null,
  munmosh: null,
  hachiyania: null,
  sakuratonagi: null,
  nekoamitozi: null,
  kitanoeko: null,
  tsukishironoela: null,
  minazukireru: null,
  cerise: null,
  kikomaya: null,
  hanatabayuiha: null,
  mikagurasuzume: null,
  mikazukichiyuru: null,
  naginomashiro: null,
  hibikikasane: null,
  kumanophay: null,
  minaseshizuku: null,
  moemidan: null,
  taito: null,
  shichihachikumano: null,
  nomuranimu: null,
  tobanaiturara: null,
  nyatenmei: null,
  rokurisyao: null,
  kakushakurin: null,
  kokuyohnoi: null,
  hayamamidori: null,
  sis: null,
  himenonoe: null,
  micosmicbaby: null,
  mizuboshiyamu: null,
  kyokashino: null
};
